import React, { useEffect,  useState, useContext } from 'react';
import axios from 'axios';
import { Auth } from "aws-amplify";
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import {Input, Alert} from 'reactstrap';

import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';

const config = require('../../config.json');

export default function ProcessOrder(props) {
const {selectedAccount} = props;
const { alertClose , alertDetails, setAlertDetails, loggedUser, validateLogin} = useContext(ProductContext);
const [loaded, setLoaded]= useState(true);
const [clientAccounts, setClientAccounts]= useState([]);
const [selectedAccounts, setSelectedAccounts] = useState();
const [orderDetails, setOrderDetails] = useState({accId:'', packageName:'', packagePrice:0, years:1, gst:0, subTotal:0, totalAmount:0 , discount:0, paymentMode:"Cash",refId:"NA"});
const [buttonDisabled, setButtonDisabled]=useState(true);

useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      fetchclientAccounts();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }
   // console.log(batchYear);
  },[loggedUser])

const fetchclientAccounts=async()=>{
try{
    setLoaded(false);
    const session = await Auth.currentSession();
    const access_token=session.idToken.jwtToken;
    const clientid = selectedAccount.clientId;
    await axios.get(`${config.api.invokeURL}/client/accounts/${clientid}`,{
        headers: {
            Authorization: access_token,
            'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
        //console.log("Res : ", response);
        if(response.data.length>0){
        setClientAccounts(response.data);
        setSelectedAccounts(response.data[0]);
        const gst=parseInt(response.data[0].packagePrice)*16/100;
        const subTotal=parseInt(response.data[0].packagePrice)+gst;
        //const totalAmount=parseFloat(subTotal-0).toFixed(2);
        setOrderDetails({...orderDetails,
            accId:response.data[0].accId,
            packageName:response.data[0].packageName,
            packagePrice:response.data[0].packagePrice,
            gst:gst,
            years:1,
            subTotal:subTotal,
            totalAmount:subTotal,
            discount:0
        });
        setButtonDisabled(false);
        setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: response.data.length + " - Records Found"
        });  
    }else{
        setButtonDisabled(true);
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "No Account details Found. Add accounts first before processing the amount."
        });  
    }
    setLoaded(true);
    }).catch((error)=>{
        setButtonDisabled(true);
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
    })
    }catch(error){
    setButtonDisabled(true);
    setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    setLoaded(true);
    }
    setLoaded(true);
}

const generateOrderID = async () => {
   // const user = selectedAccount.clientID;
    const current_datetime = new Date();
    //const current_local_datetime = new Date().toLocaleString('en-US', {timeZone: 'Asia/Kolkata'});
    const orderNoTemp = "";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    const order_no = orderNoTemp.concat(date_year,month_value, date_value, hours_value, minutes_value);
    //const orderRef_id = orderNoTemp.concat(user, date_year,month_value, date_value, hours_value, minutes_value);
    return order_no;
  //const tempTransactionDate="";
    //const orderDate = tempTransactionDate.concat(current_datetime.getFullYear(),"-",month_value,"-",date_value);
    //setOrderId(order_no);
    //setOrderRefId(orderRef_id);

   // console.log("Order No : ", order_no);
   // console.log("Ref ID : ", orderRef_id);
    //setTransactionDate(current_local_datetime);
    //await Cookies.set('orderid', order_no, { expires: 7 });

}

const generateOrderRefID = async () => {
    const user = selectedAccount.clientID;
    const current_datetime = new Date();
    //const current_local_datetime = new Date().toLocaleString('en-US', {timeZone: 'Asia/Kolkata'});
    const orderNoTemp = "";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    //const order_no = orderNoTemp.concat(date_year,month_value, date_value, hours_value, minutes_value);
    const orderRef_id = orderNoTemp.concat(user, date_year,month_value, date_value, hours_value, minutes_value);
    return orderRef_id;
  //const tempTransactionDate="";
    //const orderDate = tempTransactionDate.concat(current_datetime.getFullYear(),"-",month_value,"-",date_value);
    //setOrderId(order_no);
    //setOrderRefId(orderRef_id);

   // console.log("Order No : ", order_no);
   // console.log("Ref ID : ", orderRef_id);
    //setTransactionDate(current_local_datetime);
    //await Cookies.set('orderid', order_no, { expires: 7 });

}

const generateOrderDate = async () => {
   // const user = selectedAccount.clientID;
   // const current_datetime = new Date();
    const current_local_datetime = new Date().toLocaleString('en-US', {timeZone: 'Asia/Kolkata'});
    /*const orderNoTemp = "";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    //const order_no = orderNoTemp.concat(date_year,month_value, date_value, hours_value, minutes_value);
    const orderRef_id = orderNoTemp.concat(user, date_year,month_value, date_value, hours_value, minutes_value);*/
    return current_local_datetime;
  //const tempTransactionDate="";
    //const orderDate = tempTransactionDate.concat(current_datetime.getFullYear(),"-",month_value,"-",date_value);
    //setOrderId(order_no);
    //setOrderRefId(orderRef_id);

   // console.log("Order No : ", order_no);
   // console.log("Ref ID : ", orderRef_id);
    //setTransactionDate(current_local_datetime);
    //await Cookies.set('orderid', order_no, { expires: 7 });

}

const validateFields=()=>{
    var returnValue="Failed";
    if(orderDetails.totalAmount<1){
        returnValue="Failed";
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Order Amount is very less to be processed"
        }); 
    }else {
        returnValue="Passed";
    }
    return returnValue;
}

const handleAddNewTransaction = async (event) => {
    event.preventDefault();
    try {
        const validationResult=validateFields();
        if(validationResult==="Passed"){
        const orderid= await generateOrderID();
        const orderRefId=await generateOrderRefID();
        const orderDate=await generateOrderDate();
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        const tcart=selectedAccounts;
        tcart.category="Renew";
        tcart.cartno=1;
        tcart.total=orderDetails.subTotal;
        tcart.years=orderDetails.years;
        //const orderid=orderId;
        const params = {
            "clientId": selectedAccount.clientId,
            "orgName": selectedAccount.clientOrg,
            "pocName": selectedAccount.clientPOC,
            "pocEmail": selectedAccount.clientEmail,    
            "pocPhone": selectedAccount.clientPhone,
            "pocAddress":selectedAccount.clientAddress,
            "orderId": orderid,
            "orderRefId": orderRefId,
            "orderAmount": orderDetails.totalAmount,
            "referenceId":orderDetails.refId,
            "txStatus": "SUCCESS",
            "paymentMode": orderDetails.paymentMode,
            "txMsg": "SUCCESS",
            "subTotalwithGST":orderDetails.subTotal,
            "txTime": orderDate,
            "transactionDate" : orderDate,
            "gstCharges": orderDetails.gst,
            "discountAmount":orderDetails.discount,
            "discountValue":"NA",
            "discountCode":"Manual",
            "subTotal":orderDetails.subTotal,
            "cart":tcart
        };
        //console.log("DB Values : ", params);
        await axios.post(`${config.api.invokeUrl}/client/accounts/transactions/${orderid}`, params,{
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }})
        .then(()=>{
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message:"Order processed Successfully"
        }); 
        setLoaded(true);
        })
        .catch((error)=>{
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            })
    }
    setLoaded(true);
    }catch (err) {
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + err.message
        }); 
        setLoaded(true);
    }
}

const processAccSelection=(event)=>{
    //console.log("event : ", event.target.value);
    const selectedAcc=clientAccounts.find(acc=>(acc.accId===event.target.value));
    setSelectedAccounts(selectedAcc);
    const gst=parseInt(selectedAcc.packagePrice)*16/100;
    const subTotal=parseInt(selectedAcc.packagePrice)+gst;
    //const totalAmount=subTotal-0;
    //console.log("Acc : ", selectedAcc);
    setOrderDetails({...orderDetails,
    accId:selectedAccount.accId,
    packageName:selectedAccount.packageName,
    packagePrice:selectedAccount.packagePrice,
    gst:gst,
    years:1,
    subTotal:subTotal,
    totalAmount:subTotal,
    discount:0
    });
}

const processDiscountCalc=(event)=>{
    const discount=event.target.value;
    const totalAmount=orderDetails.subTotal-discount;
    setOrderDetails({...orderDetails,
    discount:discount,
    totalAmount:totalAmount});
}

const processYearsCalc=(event)=>{
    const years=parseInt(event.target.value);
    //console.log("price : ", selectedAccounts);
    const subTotal=parseFloat(years*parseFloat(selectedAccounts.packagePrice)).toFixed(2);
    //console.log("Total Amount : ", totalAmount);
    const gst=((subTotal*16)/100).toFixed(2);
    const totalAmount=subTotal-orderDetails.discount;
    //console.log("GST : ", gst);
    setOrderDetails({...orderDetails,
        years:years,
        totalAmount:totalAmount,
        gst:gst,
        subTotal:subTotal

    });
}


  return (
  <>
      <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

    <div>Process Order</div>

    <Card border="light" className=" bg-white shadow-sm mb-4">
       <Card.Body>     
         <Form>
         <Row>
         <Form.Group id="otp">
            <Form.Label>Select the Account</Form.Label>
            <Input type="select" name="accId"
                            value={orderDetails.accId} 
                            onChange={(event)=>{processAccSelection(event.target.value)}}
                            onSelect={(event)=>{processAccSelection(event.target.value)}}
                        >
                            {clientAccounts.map(item => {
                        return (<option key={item.accId} value={item.accId}>{item.accId}</option>);
                        })}
            </Input>
        </Form.Group>
         </Row>
         <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="packageName">
                <Form.Label>Package Name</Form.Label>
                <Input type="text" value={orderDetails.packageName} disabled
                onChange={(event)=>{setOrderDetails({...orderDetails, packageName:event.target.value})}}
               />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="packagePrice">
                <Form.Label>Package Price</Form.Label>
                <Input type="text" value={orderDetails.packagePrice} disabled
                onChange={(event)=>{setOrderDetails({...orderDetails, packagePrice:event.target.value})}}
               />
              </Form.Group>
            </Col>
    </Row>
    <Row>
    <Col md={4} className="mb-3">
        <Form.Group id="packageName">
        <Form.Label>GST</Form.Label>
        <Input type="text" value={orderDetails.gst} disabled
        onChange={(event)=>{setOrderDetails({...orderDetails, gst:event.target.value})}}
        />
        </Form.Group>
    </Col>
    <Col md={4} className="mb-3">
        <Form.Group id="packagePrice">
        <Form.Label>Sub Total</Form.Label>
        <Input type="text" value={orderDetails.subTotal} disabled
        onChange={(event)=>{setOrderDetails({...orderDetails, subTotal:event.target.value})}}
        />
        </Form.Group>
    </Col>
    <Col md={4} className="mb-3">
        <Form.Group id="packagePrice">
        <Form.Label>Payable Amount</Form.Label>
        <Input type="text" value={orderDetails.totalAmount} disabled
        onChange={(event)=>{setOrderDetails({...orderDetails, totalAmount:event.target.value})}}
        />
        </Form.Group>
    </Col>
    </Row>
    </Form>
    </Card.Body>
    </Card>

    <Card border="light" className=" bg-white shadow-sm mb-4">
       <Card.Body>     
         <Form>
         <Row>
         <Col md={6} className="mb-3">
         <Form.Group id="otp">
            <Form.Label>Select the years</Form.Label>
            <Input type="select" name="years"
            value={orderDetails.years} 
            onChange={(event)=>{processYearsCalc(event)}}> 
            <option key="1" value="1">1</option>
            <option key="2" value="2">2</option>
            <option key="3" value="3">3</option>
            <option key="4" value="4">4</option>
            <option key="5" value="5">5</option>
            </Input>
        </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
            <Form.Group id="packageName">
            <Form.Label>Discount Amount</Form.Label>
            <Input type="text" value={orderDetails.discount} 
            onChange={(event)=>{processDiscountCalc(event)}}
            />
            </Form.Group>
        </Col>
    </Row>
    <Row>
    <Col md={6} className="mb-3">
         <Form.Group id="otp">
            <Form.Label>Payment Mode</Form.Label>
            <Input type="select" name="paymentMode"
            value={orderDetails.paymentMode} 
            onChange={(event)=>{setOrderDetails({...orderDetails, paymentMode:event.target.value})}}> 
            <option key="Cash" value="Cash">Cash</option>
            <option key="Cheque" value="Cheque">Cheque</option>
            <option key="Online" value="Online">Online</option>
            </Input>
        </Form.Group>
        </Col>

        {orderDetails.paymentMode==="Cheque" || orderDetails.paymentMode==="Online"?<Col md={6} className="mb-3">
         <Form.Group id="otp">
            <Form.Label>Ref No</Form.Label>
            <Input type="text" name="refId"
            value={orderDetails.refId} 
            onChange={(event)=>{setOrderDetails({...orderDetails, refId:event.target.value})}} /> 
        </Form.Group>
        </Col>:null}
    </Row>
    <Button disabled={buttonDisabled} onClick={(event)=>{handleAddNewTransaction(event)}}>Submit</Button>
    </Form>
    </Card.Body>
    </Card>

    </>
  )
}
