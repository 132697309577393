
import React from 'react';
import { Image } from '@themesberg/react-bootstrap';
import IdnLogo from "../assets/img/idnticaicon.png";
import ReactLogo from "../assets/img/spin-circle.png";

export default (props) => {

  const { show } = props;

  return (
    <div >
      <div className={`preloader bg-soft flex-column justify-content-center align-items-center ${show ? "" : "show"}`} style={{backgroundColor:'red'}}>
      <Image className="loader-element animate__animated animate__jackInTheBox" src={ReactLogo} height={250} />
      <img   src={IdnLogo} height={150} style={{marginTop:'-200px', marginRight:'20%'}}/>
    </div>
    
    </div>
    
  );
};
