import React, { useState, useContext, useEffect } from 'react';
import { Auth } from "aws-amplify";
import { Alert  } from "reactstrap";
import Preloader from "../../components/Preloader";
import { phoneCode } from '../../AppData';
import { Col, Row, Form, Button, InputGroup, FormCheck } from '@themesberg/react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import axios from 'axios';

const config = require('../../config.json');

export default function ClientAddForm (props) {
    const {fetchClientDetails, clientAddToggle, clientFormType, selectedAccount} = props;
    const { validateLogin, alertClose , alertDetails, setAlertDetails, loggedUser} = useContext(ProductContext);
    const [phoneNo, setPhoneNo] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [orgName, setOrgName] = useState('');
    const [GSTNo, setGSTNo] = useState('');
    const [address, setAddress] = useState('');
    const [loaded, setLoaded]=useState(true);
    const [registrationDate, setRegistrationDate]=useState('');
    const [cStatus, setCStatus]= useState('');
    const [areaCode, setAreaCode]=useState('+91');
    const [cCode, setCCode] = useState('');
    const [disValue, setDisValue] = useState('');
    const [category, setCategory]=useState('Active');
    const [cSource, setCSource]=useState('');
    const [buttonDisabled, setButtonDisabled]= useState(true);
    const [website, setWebsite] = useState('');

    useEffect(()=>{
        if(loggedUser.isAuthenticated){
            const signupDate = generateOrderdate();
            if(clientFormType==="Add"){
                setButtonDisabled(false);
            }else if(clientFormType==="Edit"){
                assignValues();
                setButtonDisabled(false);
            }else{
                //console.log("selected acc : ", selectedAccount);
                assignValues();
                setButtonDisabled(true);
            }
            setRegistrationDate(signupDate);
          alertClose();
        }else{
        validateLogin();
        alertClose();
        }
        return()=>{
          alertClose();
        }
       // console.log(batchYear);
      },[loggedUser])

      const assignValues=()=>{
          const { clientCategory, clientAddress, clientEmail, clientWebsite, clientOrg, clientPOC, countryCode, clientPhone, clientRegisteredDate, clientSource, clientStatus, couponCode, discountValue , gstNo} = selectedAccount;
          setEmail(clientEmail);
          setCategory(clientCategory);
          setAddress(clientAddress);
          setCSource(clientSource);
          setCStatus(clientStatus);
          setAreaCode(countryCode);
          setGSTNo(gstNo);
          setOrgName(clientOrg);
          setPhoneNo(clientPhone);
          setName(clientPOC);
          setRegistrationDate(clientRegisteredDate);
          setDisValue(discountValue);
          setCCode(couponCode);
          setWebsite(clientWebsite);
      }

    const generateOrderdate=()=>{
        const current_datetime = new Date();
        const temp="";
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        const orderDate = temp.concat(date_year,"-",month_value,"-", date_value);
        return orderDate;
    }

    const generateUserAccount = async () => {
      const current_datetime = new Date();
      const accountNoTemp = "";
      const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
      var date_month = (current_datetime.getMonth() + 1).toString();
      var month_value="";
      date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
      var date_date = (current_datetime.getDate().toString());
      var date_value="";
      date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
      var date_hours = (current_datetime.getHours().toString());
      var hours_value="";
      date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
      var date_minutes = (current_datetime.getMinutes().toString());
      var minutes_value="";
      date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
      const accountNo = accountNoTemp.concat(date_year,month_value, date_value, hours_value, minutes_value);
      return accountNo;
    }

    const handleSubmit = async event => {
        event.preventDefault();
        const tempNameCheck=name.split(" ").join("").toUpperCase();
        try{
          if(phoneNo===""||email===""||name===""||registrationDate===""){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Enter all the Details"
            }); 
            window.scrollTo(0,0); 
            setLoaded(true);          
        }else if(tempNameCheck.length<5){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "User Name should contain atleast 5 letters!"
            }); 
            window.scrollTo(0,0); 
            setLoaded(true);   
          }
          else{
              setLoaded(false);
              const signupDate = await generateOrderdate();
              const familyName = await generateUserAccount();
              const session = await Auth.currentSession();
              const access_token=session.idToken.jwtToken;    
              const params={
                email:email,
                address:address,
                birthdate: registrationDate,
                phone_number:phoneNo,
                countryCode:areaCode,
                family_name:familyName,
                name: name,
                given_name:orgName,
                gstNo:GSTNo,
                updatedBy:loggedUser.family_name,
                updatedOn:signupDate,
                clientStatus:cStatus,
                couponCode:cCode,
                discountValue:disValue,
                category:category,
                clientSource:cSource,
                clientWebsite:website
              };
              //console.log("params : ", params); 
              await axios.post(`${config.api.invokeURL}/admin/clients`, params,{
                  headers: {
                    Authorization: access_token,
                    'x-api-key':config.api.key
                  }})
                  .then(async(response)=>{
                  // console.log("response : ", response); 
                   await fetchClientDetails();
                   clientAddToggle();
                   setAlertDetails({
                      ...alertDetails,
                      style:"success",
                      open:true,
                      message: "Client successfully Added!"
                  }); 
                  window.scrollTo(0,0); 
                  setLoaded(true);
                  }).catch((error)=>{
                      setAlertDetails({
                        ...alertDetails,
                        style:"warning",
                        open:true,
                        message: "Error Occured : " + error.message
                    }); 
                    window.scrollTo(0,0); 
                    setLoaded(true);
                   // setLoaded(true);
                  })
              }
        } catch (error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0); 
            setLoaded(true);        }
    };   

    const handleEditSave = async (event) => {
        event.preventDefault();
        const tempNameCheck=name.split(" ").join("").toUpperCase();
        try{
          if(phoneNo===""||email===""||name===""||registrationDate===""){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Enter all the Details"
            }); 
            window.scrollTo(0,0); 
            setLoaded(true);          
        }else if(tempNameCheck.length<5){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "User Name should contain atleast 5 letters!"
            }); 
            window.scrollTo(0,0); 
            setLoaded(true);   
          }
          else{
              setLoaded(false);
              const signupDate = await generateOrderdate();
             // const familyName = await generateUserAccount();
              const session = await Auth.currentSession();
              const access_token=session.idToken.jwtToken;      
              const params={
                email:email,
                address:address,
                birthdate: registrationDate,
                family_name: selectedAccount.clientId,
                phone_number:phoneNo,
                countryCode:areaCode,
                name: name,
                given_name:orgName,
                gstNo:GSTNo,
                updatedBy:loggedUser.family_name,
                clientStatus:cStatus,
                couponCode:cCode,
                discountValue:disValue,
                category:category,
                clientSource:cSource,
                clientWebsite:website,
                updatedOn:signupDate
              };
             // console.log("params : ", params); 
              await axios.patch(`${config.api.invokeURL}/admin/clients`, params,{
                  headers: {
                    Authorization: access_token,
                    'x-api-key':config.api.key
                  }})
                  .then(async(response)=>{
                  // console.log("response : ", response); 
                  if(response.status===200){
                   await fetchClientDetails();
                   setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: "Client successfully Updated!"
                    });
                    clientAddToggle();
                  }
                  else{
                    setAlertDetails({
                        ...alertDetails,
                        style:"warning",
                        open:true,
                        message: "Error Occured : " + response.data
                    }); 
                  }
                  window.scrollTo(0,0); 
                  setLoaded(true);
                  }).catch((error)=>{
                      setAlertDetails({
                        ...alertDetails,
                        style:"warning",
                        open:true,
                        message: "Error Occured : " + error.message
                    }); 
                    window.scrollTo(0,0); 
                    setLoaded(true);
                   // setLoaded(true);
                  })
              }
        } catch (error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0); 
            setLoaded(true);        }
    };   
      
    return (
    <>
        <div>  
            <Preloader show={loaded ? false : true} />
            <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
            {alertDetails.message}
            </Alert>
            <div className="table-settings mb-4">
            <Row>
            <Col md={6} className="mb-3">
            <Form.Label>Organisation Name</Form.Label>
            <InputGroup>
                <InputGroup.Text>
                </InputGroup.Text>
                <Form.Control type="text" value={orgName} disabled={buttonDisabled}
                onChange={(event)=>{setOrgName(event.target.value)}}/>
                </InputGroup>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Label>POC Name</Form.Label>
            <InputGroup>
                <InputGroup.Text>
                </InputGroup.Text>
                <Form.Control type="text" value={name} disabled={buttonDisabled}
                onChange={(event)=>{setName(event.target.value)}}/>
                </InputGroup>
            </Col>

            </Row>
            <Row>
            <Col md={6} className="mb-3">
            <Form.Label>Email Address </Form.Label>
            <InputGroup>
                <InputGroup.Text>
                </InputGroup.Text>
                <Form.Control type="email" value={email} disabled={buttonDisabled}
                    onChange={(event)=>{setEmail(event.target.value)}}/>
                </InputGroup>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Label>Phone Number:  </Form.Label>
            <Form.Group id="searchstud">  
            <InputGroup>
            <Col md={4} className="mb-3">
                <Form.Select disabled={buttonDisabled}
                value={areaCode} onChange={(event)=>{setAreaCode(event.target.value)}}>
                    {phoneCode.map(item => {
                    return (<option key={item.Country} value={item.Code}>{item.Code}</option>);
                })}
                </Form.Select>
                </Col>
                <Col md={8} className="mb-3">
                <Form.Control type="text" value={phoneNo} disabled={buttonDisabled}
                onChange={(event)=>{setPhoneNo(event.target.value)}}/>
                </Col>
                </InputGroup>
                </Form.Group>
            </Col>
            </Row>
           
            <Row>
            <Col md={6} className="mb-3">
            <Form.Label>Category</Form.Label>
                <InputGroup>
                <InputGroup.Text>
                </InputGroup.Text>
                <Form.Control type="text" value={category} disabled={buttonDisabled}
                onChange={(event)=>{setCategory(event.target.value)}}/>
                </InputGroup>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Label>GST NO</Form.Label>
                <InputGroup>
                <InputGroup.Text>
                </InputGroup.Text>
                <Form.Control type="text" value={GSTNo} disabled={buttonDisabled}
                onChange={(event)=>{setGSTNo(event.target.value)}}/>
                </InputGroup>
            </Col>
            </Row>
            <Row>
            <Col md={6} className="mb-3">
            <Form.Label>Registered Date</Form.Label>
                <InputGroup>
                <InputGroup.Text>
                </InputGroup.Text>
                <Form.Control type="date" value={registrationDate} disabled={buttonDisabled}
                onChange={(event)=>{setRegistrationDate(event.target.value)}}/>
                </InputGroup>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Label>Status</Form.Label>
                <InputGroup>
                <InputGroup.Text>
                </InputGroup.Text>
                <Form.Select disabled={buttonDisabled}
                value={cStatus} onChange={(event)=>{setCStatus(event.target.value)}}>
                    <option key="Active" value="Active">Active</option>
                    <option key="InActive" value="In Active">In Active</option>
                </Form.Select>                
                </InputGroup>
            </Col>
            </Row>
            <Row>
            <Col md={6} className="mb-3">
            <Form.Label>Coupon Code</Form.Label>
                <InputGroup>
                <InputGroup.Text>
                </InputGroup.Text>
                <Form.Control type="text" value={cCode} disabled={buttonDisabled}
                onChange={(event)=>{setCCode(event.target.value)}}/>
                </InputGroup>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Label>Discount %</Form.Label>
                <InputGroup>
                <InputGroup.Text>
                </InputGroup.Text>
                <Form.Control type="text" value={disValue} disabled={buttonDisabled}
                onChange={(event)=>{setDisValue(event.target.value)}}/>
                </InputGroup>
            </Col>
            </Row>
            <Row>
            <Col md={6} className="mb-3">
            <Form.Label>Website</Form.Label>
            <InputGroup>
                <InputGroup.Text>
                </InputGroup.Text>
                <Form.Control type="text" value={website} disabled={buttonDisabled}
                onChange={(event)=>{setWebsite(event.target.value)}}/>
                </InputGroup>
                </Col>

            <Col md={6} className="mb-3">
            <Form.Label>Source</Form.Label>
            <InputGroup>
                <InputGroup.Text>
                </InputGroup.Text>
                <Form.Control type="text" value={cSource} disabled={buttonDisabled}
                onChange={(event)=>{setCSource(event.target.value)}}/>
                </InputGroup>
                </Col>
            </Row>
            <Row>
            <Form.Label>Address</Form.Label>
            <InputGroup>
                <InputGroup.Text>
                </InputGroup.Text>
                <Form.Control type="text" value={address} disabled={buttonDisabled}
                onChange={(event)=>{setAddress(event.target.value)}}/>
                </InputGroup>
            </Row>
            <Row>
            <div className="text-center mb-2 mb-md-0">
            {clientFormType==="Add"?<Button variant="primary" type="submit" onClick={(event)=>{handleSubmit(event)}}>Add</Button>
            :clientFormType==="Edit"?<Button variant="primary" type="submit" onClick={(event)=>{handleEditSave(event)}}>Save</Button>
            :clientFormType==="View"?null:null}

            </div>
            </Row>
        </div>
        </div>
     </>
    )
}
