
export const Routes = {
    // pages
    //Presentation: { path: "/" },
    LandingPage: { path: "/" },
    //DashboardOverview: { path: "/dashboard/overview" },
    //BootstrapTables: { path: "/tables/bootstrap-tables" },
    //Billing: { path: "/examples/billing" },
    //Invoice: { path: "/examples/invoice" },
    Signin: { path: "/dashboard/sign-in" },
    //Signup: { path: "/examples/sign-up" },
    ForgotPassword: { path: "/dashboard/forgot-password" },
    ResetPassword: { path: "/dashboard/change-password" },
    //Lock: { path: "/examples/lock" },
    NotFound: { path: "/examples/404" },
    ServerError: { path: "/examples/500" },

    //Images
    GalleryViewer: { path: "/galleryview" },
    ProjectsViewer: { path: "/projectview" },
    SlideViewer: { path: "/slideview" },


    //MyProfile
    MyProfile:{path:"/myprofile"},
    Clients:{path:"/clients"},
    ExpiringAccounts:{path:"/expiring-accounts"},
    OrderDetails:{path:"/order-details"},


    //Email
    SeasonalEmail:{path:"/seasonalemail"}



};