import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";
import Amplify from 'aws-amplify';
import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProductProvider } from './ProductProvider';
// core styles
import "./scss/main.scss";
// vendor styles
//import "react-datetime/css/react-datetime.css";
import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";
import config from './config';

Amplify.configure ({
  Auth: {
      mandatorySignId: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
  }
});


ReactDOM.render(
  <HashRouter>
      <ProductProvider>
    <ScrollToTop />
    <HomePage />
    </ProductProvider>

  </HashRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
