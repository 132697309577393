import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { Auth } from "aws-amplify";
import {Breadcrumb, BreadcrumbItem  } from "reactstrap";
import { Link} from "react-router-dom";
import {Routes} from '../../routes';
import Preloader from "../../components/Preloader";
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import { Button, Dropdown, Table, ButtonGroup } from '@themesberg/react-bootstrap';
import { faEye, faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductContext } from '../../ProductProvider';
import ClientAccountsView from './ClientAccountsView';
import ClientOrdersView from './ClientOrdersView';

const config = require('../../config.json');

export default function AccountsExpiring() {
  const { validateLogin, alertClose , alertDetails, setAlertDetails, loggedUser} = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);
  const [clientViewCollapsed, setClientViewCollapsed] = useState(true);
  const [orderViewCollapsed, setOrderViewCollapsed] = useState(true);
  const [clientDetails, setClientDetails]= useState([]);
  const [selectedAccount, setSelectedAccount] = useState();


  useEffect(() => {
    alertClose();
    if(loggedUser.isAuthenticated){
        fetchExpiringAccounts();  
    }else{
        validateLogin();
    }
}, [loggedUser]);


    const fetchExpiringAccounts=async()=>{
       // console.log("fetching clients list");
        try{
          setLoaded(false);
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          //const clientID="VJDEPTH";
          //const status="Active";
          //const selectedBatch="All";
          await axios.get(`${config.api.invokeURL}/admin/clients/expiring-clients`,{
              headers: {
                Authorization: access_token,
                'x-api-key': config.api.key
              }}, 
          ).then((response)=>{
            //console.log("Response : ", response);
              setClientDetails(response.data);
              setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: response.data.length + " - Records Found"
              });  
              //console.log(response);
              setLoaded(true);
          }).catch((error)=>{
              //console.log(error);
              setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message:"Error Occured - " + error.message
              }); 
              setLoaded(true);
              //setDispMsg(error.message);
          })
        }catch(error){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
        }
    }

    const clientViewToggle=()=>{
      setClientViewCollapsed(!clientViewCollapsed);
    }

    const handleViewAccounts=async(event, item)=>{
        event.preventDefault();
        setSelectedAccount(item.client);
        clientViewToggle();
    }

    const orderViewToggle=()=>{
      setOrderViewCollapsed(!orderViewCollapsed);
    }

    const handleViewOrders=async(event, item)=>{
      event.preventDefault();
      setSelectedAccount(item.client);
      clientViewToggle();
  }

const handleEmailResend=async(event, item)=>{
  event.preventDefault();
  //setSelectedAccount(item);
  try{
    setLoaded(false);
    const session = await Auth.currentSession();
    const access_token=session.idToken.jwtToken;
    await axios.get(`${config.api.invokeURL}/admin/clients/expiring-clients/email-trigger?clientOrg=${item.client.clientOrg}&clientId=${item.client.clientId}&validTo=${item.accounts.validTo}&packageName=${item.accounts.packageName}&clientPOC=${item.client.clientPOC}&discountValue=${item.client.discountValue}&couponCode=${item.client.couponCode}&clientEmail=${item.client.clientEmail}`,{
        headers: {
          Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
      //console.log("Response : ", response);
       // setClientDetails(response.data);
        setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: "Email Notification Sent Successfully!"
        });  
        //console.log(response);
        setLoaded(true);
    }).catch((error)=>{
        //console.log(error);
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
        //setDispMsg(error.message);
    })
  }catch(error){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message:"Error Occured - " + error.message
  }); 
  setLoaded(true);
  }
}
    
  return (
    <> 
    <Preloader show={loaded ? false : true} />
    <Breadcrumb>
    <BreadcrumbItem>
      <Link to={Routes.LandingPage.path}>
        Home
      </Link>
    </BreadcrumbItem>
    <BreadcrumbItem >
      Expiring Accounts
    </BreadcrumbItem>
  </Breadcrumb>

        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
          {/*View Accounts*/}
        <Modal toggle={clientViewToggle} isOpen={!clientViewCollapsed} style={{top:'10%', maxWidth:'800px'}}>
                    <ModalHeader toggle={clientViewToggle}>
                      Accounts
                    </ModalHeader>
                    <ModalBody>    
                    <div>
                            <ClientAccountsView selectedAccount={selectedAccount}/>                        
                      </div>
                    </ModalBody>
                    <ModalFooter>
            {' '}
            <Button onClick={clientViewToggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>
         {/*View Orders*/}
        <Modal toggle={orderViewToggle} isOpen={!orderViewCollapsed} style={{top:'10%', maxWidth:'800px'}}>
          <ModalHeader toggle={orderViewToggle}>
            Accounts
          </ModalHeader>
          <ModalBody>    
          <div>
                  <ClientOrdersView selectedAccount={selectedAccount}/>                        
            </div>
          </ModalBody>
          <ModalFooter>
            {' '}
            <Button onClick={orderViewToggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>

        <Table responsive hover className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Client ID</th>
                <th>Organisation</th>
                <th>Registered Date</th>
                <th>package Name</th>
                <th>Expiring Date</th>
                <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {clientDetails.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.client.clientId}</td>
            <td>{item.client.clientOrg}</td>
            <td>{item.client.clientRegisteredDate}</td>
            <td>{item.accounts.packageName}</td>
            <td>{item.accounts.validTo}</td>

           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
            <Dropdown.Item  onClick={(event)=>{handleEmailResend(event, item)}} >
                <FontAwesomeIcon icon={faEye} className="me-2" />  Resend Email
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleViewAccounts(event, item)}} >
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Accounts
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleViewOrders(event, item)}} >
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Orders
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
        </Table>

       {clientDetails.length>0? <h6>Note: Above accounts are expiring in next 90 days.</h6>:
       <h6>Note: No Accounts expiring in next 90 days.</h6>}
    </>
    )
}
