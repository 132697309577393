import React, { useEffect, useContext, useState } from 'react'
import axios from 'axios';
import { Auth } from "aws-amplify";
import {Alert  } from "reactstrap";
import { Button, Dropdown, Table, ButtonGroup } from '@themesberg/react-bootstrap';
import { faEye, faEllipsisH, faPaperclip, faDownload, faMailForward} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import InvoiceDownloader from './InvoiceDownloader';
const config = require('../../config.json');

export default function ClientOrdersView(props) {
    const {selectedAccount} = props;
    const { alertClose , alertDetails, setAlertDetails} = useContext(ProductContext);
    const [loaded, setLoaded]= useState(true);
    const [clientAccounts, setClientAccounts]=useState([]);


    useEffect(()=>{
        alertClose();
        fetchclientOrders();
    },[])

    const fetchclientOrders=async()=>{
        try{
            setLoaded(false);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            const clientid = selectedAccount.clientId;
            await axios.get(`${config.api.invokeURL}/client/accounts/transactions/mytransaction/${clientid}`,{
                headers: {
                  Authorization: access_token,
                  'x-api-key': config.api.key
                }}, 
            ).then((response)=>{
                setClientAccounts(response.data);
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: response.data.length + " - Records Found"
                });  
                setLoaded(true);
            }).catch((error)=>{
                //console.log(error);
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + error.message
                }); 
                setLoaded(true);
                //setDispMsg(error.message);
            })
          }catch(error){
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          }); 
          setLoaded(true);
          }
          setLoaded(true);
    }

   const triggerEmailInvoice =async(item)=>{
      //setEmailMsg('');

      try {
        setLoaded(false);
         const session = await Auth.currentSession();
         //const clientId=session.idToken.payload.family_name;
         //const clientId=username.substring(1, username.length);
         const access_token=session.idToken.jwtToken;
        //console.log("POC Email : ", item);
        const params={
          clientid:item.clientId,
          orderid:item.orderId,
          pocName:item.pocName,
          pocEmail:item.pocEmail
        }
        //console.log("Params :", params);

         //await axios.get(`${config.api.invokeUrl}/transaction/email_invoice?orderId=${tempOrderid}&clientId=${tempClientid}&pocName=${pocName}&pocEmail=${pocEmail}`, {
          await axios.post(`${config.api.invokeURL}/transaction/email_invoice`,params, { 
         headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          }).then(async(res) => {
            //console.log("Res:", res);
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: "Email Successfully sent!"
          });  
          setLoaded(true);

          // console.log("response : ", res);
          /* const session = await Auth.currentSession();
           const access_token=session.idToken.jwtToken;
           const params={
            clientId: newOrder.clientId,
            orderno:newOrder.orderId,
            invoiceCreated:ispdfGenerated,
            emailSent:true
           }
           await axios.patch(`${config.api.invokeUrl}/transaction/cfresponse`, params, {
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          }).then(()=>{
          }).catch((err)=>{
          //  console.log("Update error : ", err);
          });*/
          }, (error) => {
            console.log("Res error :", error);
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          }); 
          setLoaded(true);
          });
         
          } catch (err) {
            console.log("err:", err);
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + err.message
          }); 
          setLoaded(true);
        }  
   }


    const generateInvoice = async(tempData) =>{
      console.log("generating pdf", tempData);
      setLoaded(false);
      try{
         //const pdfurl="https://appbhwubyc.execute-api.ap-south-1.amazonaws.com/v1/transaction/invoice";
         const session = await Auth.currentSession();
         //const username=session.idToken.payload.phone_number;
         //const clientId=username.substring(1, username.length);
         const access_token=session.idToken.jwtToken;

         const params = {
            "clientId": tempData.clientId,
            "orgName": tempData.orgName,
            "pocName": tempData.pocName,
            "pocAddress":"",
            "pocEmail": tempData.pocEmail,    
            "pocPhone": tempData.pocPhone,
            "orderId": tempData.orderId,
            "orderAmount": tempData.orderAmount,
            "paymentMode": tempData.paymentMode,
            "transactionDate" : tempData.transactionDate,
            "subTotal":tempData.subTotal,
            "gstCharges": tempData.gstCharges,
            "discountAmount":tempData.discountAmount,
            "cart":tempData.cart
          };
        console.log("Params : ", params);
        await axios.post(`${config.api.invokeURL}/transaction/invoice`, params, {
         headers: {
           Authorization: access_token,
           'x-api-key':config.api.key
         }
       }).then((response) => {
           console.log("response : ", response);
           if(response.status===200 && response.headers['content-length']>10000){
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: "Invoice Re-created Successfully!"
          });  
          setLoaded(true);

            //  setIspdfGenerated(true);
            //  triggerEmailInvoice(tempData.clientId,tempData.orderId,tempData.pocName,tempData.pocEmail);
           }else{
          //  generateInvoice(tempData);
           }
        
       },(error) => {
          console.log("Error Occured : ", error);
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
     //   generateInvoice(tempData);
       });
      
       } catch (err) {
        //setDispMsg("Error Occured : ", err.message);
        console.log("Errors Occured : ", err);
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + err.message
      }); 
      setLoaded(true);
     }  
     setLoaded(true);   
   } 

  return (
    <> 
    <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
          {/*Edit*/}
        <Table responsive hover className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Order ID</th>
                <th>Order Date</th>
                <th>Order Status</th>
                <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {clientAccounts.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.orderId}</td>
            <td>{item.transactionDate}</td>
            <td>
            {item.txStatus==="SUCCESS"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>{item.txStatus}</p>:
            <p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>{item.txStatus}</p>}
            </td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
            {item.txStatus==="SUCCESS"?
            <Dropdown.Item><InvoiceDownloader propsFile={item} /></Dropdown.Item>
            :null}
              {/*<Dropdown.Item   onClick={()=>{generateInvoice(item)}} >
                <FontAwesomeIcon icon={faDownload} className="me-2" /> Download Invoice
            </Dropdown.Item>*/}
              {item.txStatus==="SUCCESS"?
              <Dropdown.Item   onClick={()=>{triggerEmailInvoice(item)}} >
                <FontAwesomeIcon icon={faMailForward} className="me-2" /> Email Invoice
              </Dropdown.Item>:null}
              {item.txStatus==="SUCCESS"?
              <Dropdown.Item   onClick={()=>{generateInvoice(item)}} >
                <FontAwesomeIcon icon={faPaperclip} className="me-2" /> Recreate Invoice
              </Dropdown.Item>:null}

            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
        </Table>
    </>  )
}
