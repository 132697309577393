import React from 'react';
import { Col, Row, Form, Card, Table } from '@themesberg/react-bootstrap';
import {Input} from 'reactstrap';

export default function ViewOrder(props) {
    const { selectedOrder} = props;
    
  return (
      <>
      <Form>
      <h4>Client Details</h4>
      <Card>
      <Row>
        <Col md={6} className="mb-3">
        <Form.Group id="otp">
                <Form.Label>Client ID</Form.Label>
                <Input type="text" name="courseName"
                    value={selectedOrder.clientId} disabled>
                </Input>
            </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Group id="otp">
                <Form.Label>Order ID</Form.Label>
                <Input type="text" name="courseOption"
                    disabled value={selectedOrder.orderId}>
                </Input>
            </Form.Group>
            </Col>
        </Row>
        <Row>
        <Col md={6} className="mb-3">
        <Form.Group id="otp">
                <Form.Label>ORG Name</Form.Label>
                <Input type="text" name="courseName"
                    value={selectedOrder.orgName} disabled>
                </Input>
            </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Group id="otp">
                <Form.Label>POC Name</Form.Label>
                <Input type="text" name="courseOption"
                    disabled value={selectedOrder.pocName}>
                </Input>
            </Form.Group>
            </Col>
        </Row>
        <Row>
        <Col md={6} className="mb-3">
        <Form.Group id="otp">
                <Form.Label>Email</Form.Label>
                <Input type="text" name="courseName"
                    value={selectedOrder.pocEmail} disabled>
                </Input>
            </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Group id="otp">
                <Form.Label>Phone</Form.Label>
                <Input type="text" name="courseOption"
                    disabled value={selectedOrder.pocPhone}>
                </Input>
            </Form.Group>
            </Col>
        </Row> 
        </Card>
        <h4>Transaction Details</h4>
        <Card>
        <Row>
        <Col md={6} className="mb-3">
        <Form.Group id="otp">
                <Form.Label>Transaction ID</Form.Label>
                <Input type="text" name="courseName"
                    value={selectedOrder.referenceId} disabled>
                </Input>
            </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Group id="otp">
                <Form.Label>Date</Form.Label>
                <Input type="text" name="courseOption"
                    disabled value={selectedOrder.transactionDate}>
                </Input>
            </Form.Group>
            </Col>
        </Row> 
        <Row>
        <Col md={6} className="mb-3">
        <Form.Group id="otp">
                <Form.Label>Transaction Status</Form.Label>
                <Input type="text" name="courseName"
                    value={selectedOrder.txStatus} disabled>
                </Input>
            </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Group id="otp">
                <Form.Label>Payment Mode</Form.Label>
                <Input type="text" name="courseOption"
                    disabled value={selectedOrder.paymentMode}>
                </Input>
            </Form.Group>
            </Col>
        </Row>
        <Row>
        <Col md={6} className="mb-3">
        <Form.Group id="otp">
                <Form.Label>Sub Total</Form.Label>
                <Input type="text" name="courseName"
                    value={selectedOrder.subTotal} disabled>
                </Input>
            </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Group id="otp">
                <Form.Label>GST</Form.Label>
                <Input type="text" name="courseOption"
                    disabled value={selectedOrder.gstCharges}>
                </Input>
            </Form.Group>
            </Col>
        </Row> <Row>
        <Col md={6} className="mb-3">
        <Form.Group id="otp">
                <Form.Label>Discount %</Form.Label>
                <Input type="text" name="courseName"
                    value={selectedOrder.discountValue} disabled>
                </Input>
            </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Group id="otp">
                <Form.Label>Discount</Form.Label>
                <Input type="text" name="courseOption"
                    disabled value={selectedOrder.discountAmount}>
                </Input>
            </Form.Group>
            </Col>
        </Row> <Row>
        <Col md={6} className="mb-3">
            </Col>
            <Col md={6} className="mb-3">
            <Form.Group id="otp">
                <Form.Label>Order Amount</Form.Label>
                <Input type="text" name="courseOption"
                    disabled value={selectedOrder.orderAmount}>
                </Input>
            </Form.Group>
            </Col>
        </Row> 
        </Card>
        <h4>Package Details</h4>
        <Card>

        <Table responsive hover className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Package ID</th>
                <th>Package Name</th>
                <th>Package Details</th>
                <th>Package Price</th>
                <th>Quantity/Years</th>
                <th>Start</th>
                <th>End</th>                

                </tr>
            </thead>
            <tbody>
            {selectedOrder.cart.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.packageId}</td>
            <td>{item.packageName}</td>
            <td>{item.packageDetails}</td>
            <td>{item.packagePrice}</td>
            <td>{item.years}</td>
            <td>{item.validFrom}</td>
            <td>{item.validTo}</td>
            </tr>)}
            </tbody>
        </Table>
        </Card>
        </Form>
      </>
  )
}
