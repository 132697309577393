import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { Auth } from "aws-amplify";
import {Breadcrumb, BreadcrumbItem  } from "reactstrap";
import Preloader from "../../components/Preloader";
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import { Button, Dropdown, Table, ButtonGroup } from '@themesberg/react-bootstrap';
import { faEye, faPlus, faEllipsisH, faAdd, faPen} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductContext } from '../../ProductProvider';
import ClientAccountsView from './ClientAccountsView';
import ClientOrdersView from './ClientOrdersView';
import ProcessOrder from './ProcessOrder';
import { Link} from "react-router-dom";
import {Routes} from '../../routes';
import ClientAddForm from './ClientAddForm';
import AccountsAddForm from './AccountsAddForm';

const config = require('../../config.json');

export default function ClientsView() {
  const { validateLogin, alertClose , alertDetails, setAlertDetails, loggedUser} = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);
  const [clientViewCollapsed, setClientViewCollapsed] = useState(true);
  const [orderViewCollapsed, setOrderViewCollapsed] = useState(true);
  const [processOrderCollapsed, setProcessOrderCollapsed] = useState(true);
  const [clientAddCollapsed, setClientAddCollapsed] = useState(true);
  const [accountAddCollapsed, setAccountAddCollapsed] = useState(true);
  const [clientDetails, setClientDetails]= useState([]);
  const [selectedAccount, setSelectedAccount] = useState();
  const [clientFormType, setClientFormType]= useState('View');

    useEffect(() => {
        alertClose();
        if(loggedUser.isAuthenticated){
            fetchClientDetails();  
        }else{
            validateLogin();
        }
    }, [loggedUser]);

    const fetchClientDetails=async()=>{
       // console.log("fetching clients list");
        try{
          setLoaded(false);
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          await axios.get(`${config.api.invokeURL}/admin/clients`,{
              headers: {
                Authorization: access_token,
                'x-api-key': config.api.key
              }}, 
          ).then((response)=>{
            //console.log("Response : ", response);
              setClientDetails(response.data);
              setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: response.data.length + " - Records Found"
              });  
              //console.log(response);
              setLoaded(true);
          }).catch((error)=>{
              //console.log(error);
              setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message:"Error Occured - " + error.message
              }); 
              setLoaded(true);
              //setDispMsg(error.message);
          })
        }catch(error){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
        }
    }

    const clientViewToggle=()=>{
      setClientViewCollapsed(!clientViewCollapsed);
    }

    const handleViewAccounts=async(event, item)=>{
        event.preventDefault();
        setSelectedAccount(item);
        clientViewToggle();
    }

    const handleAddAccounts=async(event, item, type)=>{
      event.preventDefault();
      setSelectedAccount(item);
      accountsAddToggle();

  }

    const orderViewToggle=()=>{
      setOrderViewCollapsed(!orderViewCollapsed);
    }

    const handleViewOrders=async(event, item)=>{
      event.preventDefault();
      setSelectedAccount(item);
      orderViewToggle();
  }

  const processOrderToggle=()=>{
    setProcessOrderCollapsed(!processOrderCollapsed);
  }

  const handleProcessOrders=async(event, item)=>{
    event.preventDefault();
    setSelectedAccount(item);
    processOrderToggle();
}

const clientAddToggle = () => {
  //   console.log("clicked");
       setClientAddCollapsed(!clientAddCollapsed);
   }

   const accountsAddToggle = () => {
    //   console.log("clicked");
         setAccountAddCollapsed(!accountAddCollapsed);
     }

const handleClientAdd=(event, type)=>{
  event.preventDefault();
  setClientFormType(type);
  clientAddToggle();
}

const handleClientForm=(event, data, type)=>{
  event.preventDefault();
  setSelectedAccount(data);
  setClientFormType(type);
  clientAddToggle();
}


    
  return (
    <> 
    <Preloader show={loaded ? false : true} />
    <Breadcrumb>
    <BreadcrumbItem>
      <Link to={Routes.LandingPage.path}>
        Home
      </Link>
    </BreadcrumbItem>
    <BreadcrumbItem >
      My Clients
    </BreadcrumbItem>
  </Breadcrumb>

        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

         {/*Clients Form*/}
         <Modal toggle={clientAddToggle} isOpen={!clientAddCollapsed} style={{top:'10%', maxWidth:'800px'}}>
                    <ModalHeader toggle={clientAddToggle}>
                      Clients - {clientFormType} Form
                    </ModalHeader>
                    <ModalBody>    
                    <div>
                      <ClientAddForm selectedAccount={selectedAccount} clientFormType={clientFormType} fetchClientDetails={fetchClientDetails} clientAddToggle={clientAddToggle} />
                      </div>
                    </ModalBody>
                    <ModalFooter>
            {' '}
            <Button onClick={clientAddToggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>

          {/*Add Packages / Accounts*/}
          <Modal toggle={accountsAddToggle} isOpen={!accountAddCollapsed} style={{top:'10%', maxWidth:'800px'}}>
                    <ModalHeader toggle={accountsAddToggle}>
                      Clients - Add Form
                    </ModalHeader>
                    <ModalBody>    
                    <div>
                      <AccountsAddForm selectedClient={selectedAccount} accountsAddToggle={accountsAddToggle} />
                      </div>
                    </ModalBody>
                    <ModalFooter>
            {' '}
            <Button onClick={accountsAddToggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>

          {/*View Accounts*/}
        <Modal toggle={clientViewToggle} isOpen={!clientViewCollapsed} style={{top:'10%', maxWidth:'800px'}}>
                    <ModalHeader toggle={clientViewToggle}>
                      Accounts
                    </ModalHeader>
                    <ModalBody>    
                    <div>
                            <ClientAccountsView selectedAccount={selectedAccount}/>                        
                      </div>
                    </ModalBody>
                    <ModalFooter>
            {' '}
            <Button onClick={clientViewToggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>
         {/*View Orders*/}
        <Modal toggle={orderViewToggle} isOpen={!orderViewCollapsed} style={{top:'10%', maxWidth:'800px'}}>
          <ModalHeader toggle={orderViewToggle}>
            Order History
          </ModalHeader>
          <ModalBody>    
          <div>
                  <ClientOrdersView selectedAccount={selectedAccount}/>                        
            </div>
          </ModalBody>
          <ModalFooter>
            {' '}
            <Button onClick={orderViewToggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>

         {/*Process Orders*/}
         <Modal toggle={processOrderToggle} isOpen={!processOrderCollapsed} style={{top:'10%', maxWidth:'800px'}}>
          <ModalHeader toggle={processOrderToggle}>
            Accounts
          </ModalHeader>
          <ModalBody>    
          <div>
                  <ProcessOrder selectedAccount={selectedAccount}/>                        
            </div>
          </ModalBody>
          <ModalFooter>
            {' '}
            <Button onClick={processOrderToggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>

        <Dropdown>
            <Dropdown.Toggle as={Button} onClick={(event)=>{handleClientAdd(event, "Add")}} variant="secondary" className="text-dark me-2">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Dropdown.Toggle>
          </Dropdown>


        <Table responsive hover className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Client ID</th>
                <th>Organisation</th>
                <th>Client Name</th>
                <th>Registered Date</th>
                <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {clientDetails.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.clientId}</td>
            <td>{item.clientOrg}</td>
            <td>{item.clientPOC}</td>
            <td>{item.clientRegisteredDate}</td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
            <Dropdown.Item  onClick={(event)=>{handleClientForm(event, item, "View")}} >
                <FontAwesomeIcon icon={faEye} className="me-2" /> View {item.clientOrg}
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleClientForm(event, item, "Edit")}} >
                <FontAwesomeIcon icon={faPen} className="me-2" /> Edit {item.clientOrg}
              </Dropdown.Item>

              <Dropdown.Item  onClick={(event)=>{handleViewAccounts(event, item)}} >
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Accounts
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleViewOrders(event, item)}} >
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Orders
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleProcessOrders(event, item)}} >
                <FontAwesomeIcon icon={faPen} className="me-2" /> Process Orders
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleAddAccounts(event, item, "Add")}} >
                <FontAwesomeIcon icon={faAdd} className="me-2" /> Add Accounts
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
        </Table>
    </>
    )
}
