import React, { useState, useContext } from 'react'
import { Alert  } from "reactstrap";
import { Form, Row, Card } from '@themesberg/react-bootstrap';
import { ProductContext } from '../../ProductProvider';

const config = require('../../config.json');

export default function PreviewEmail(props) {
    const {emailFileSelection, emailSubject, setEmailSubject, emailMsgHeader, setEmailMsgHeader, previewToggle}=props;
    const { alertClose, alertDetails } = useContext(ProductContext);


  return (
    <>
    <Alert color={alertDetails.style} fade="true" isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form>
        <Row>
            <Form.Group id="emailSubject">
            <Form.Label>Email Subject</Form.Label>
            <Form.Control required type="text" value={emailSubject} onChange={(event)=>{setEmailSubject(event.target.value)}}/>
            </Form.Group>
        </Row>
        <Row>
            <Form.Group id="msgHeader">
            <Form.Label>Message Header</Form.Label>
            <Form.Control type="text" value={emailMsgHeader}
            onChange={(event)=>setEmailMsgHeader(event.target.value)} />
            </Form.Group>
          </Row>
        </Form>
    </Card.Body>
    </Card>
    <Card>
        <table style={{fontFamily:"sans-serif", role:"presentation", cellPadding:"0", cellSpacing:"0", width:"100%", border:"0"}}>
            <tbody>
                <tr>
                <td style={{overflowWrap:"break-word", wordBreak:"break-word", fontFamily:'Cabin', align:"left"}}>
                    <table width="100%" cellPadding="0" cellSpacing="0" border="0">
                    <tr>
                        <img align="center" border="0" src="https://idntica.com/images/email/idnticaHeader.png" alt="Image" title="Image" style={{outline: "none", textDecoration: "none", msInterpolationMode: "bicubic", clear: "both", display: "inlineBlock", border: "none", height: "auto", float: "none", width:"100%"}} />
                    </tr>
                    </table>
                </td>
                </tr>
                <tr style={{boxSizing: "border-box", display: "inline-block", fontFamily:'Cabin', textDecoration: "none", WebkitTextSizeAdjust: "none", textAlign: "center", color: "#9033FF", backgroundColor: "#9033FF",  borderRadius: "4px",  WebkitBorderRadius: "4px", MozBorderRadius: "4px", width:"100%", maxWidth:"100%", overflowWrap: "break-word", wordBreak: "break-word", wordWrap:"break-word", msoBorderAlt: "none"}}>
                <td>
                    <a href="https://idntica.com/" target="_blank" style={{boxSizing: "border-box", display: "inline-block", fontFamily:'sans-serif', textDecoration: "none", WebkitTextSizeAdjust: "none", textAlign: "center", color: "#FFFFFF", backgroundColor: "#9033FF", borderRadius: "4px", webBorderRadius: "4px", MozBorderRadius: "4px", width:"auto", maxWidth:"100%", overflowWrap: "break-word", wordBreak: "break-word", wordWrap:"break-word", msoBorderAlt: "none"}}>
                    <span style={{display:"block", padding:"14px 44px 13px", lineHeight:"120%"}} ><span style={{fontSize: "16px",  lineHeight: "19.2px"}}><strong><span style={{lineHeight: "19.2px", fontSize: "26px"}}>{emailMsgHeader}</span></strong>
                    </span>
                    </span>
                    </a>
                </td>
                </tr>
            </tbody>
        </table>
        <table style={{fontFamily:'sans-serif', role:"presentation", cellPadding:"0", cellSpacing:"0", width:"100%", border:"0"}}>
            <tbody>
                <tr>
                <td style={{paddingRight: "0px", paddingLeft: "0px", align:"center"}}>
                    <img align="center" border="0" src={`${config.s3.invokeUrl}${emailFileSelection}`} alt="Image" title="Image" style={{outline: "none", textDecoration: "none", msInterpolationMode: "bicubic", clear: "both", display: "flex", border: "none", height: "auto", float: "center", width: "50%", maxWidth: "200px", width:"300"}} />
                </td>
                </tr>
            </tbody>
        </table> 
        <table style={{fontFamily:'sans-serif', role:"presentation", cellPadding:"0", cellSpacing:"0", width:"100%", border:"0"}}>
            <tbody>
                <tr>
                <td style={{overflowWrap:"break-word", wordBreak:"break-word", padding:"33px 55px 60px", fontFamily:'sans-serif', align:"left"}}>
                <div style={{lineHeight: "160%", textAlign: "center", wordWrap: "break-word"}}>
                <p style={{lineHeight: "160%", fontSize: "14px"}}><span style={{fontSize: "18px", lineHeight: "28.8px"}}>Thanks,</span></p>
                <p style={{lineHeight: "160%", fontSize: "14px"}}><span style={{fontSize: "18px", lineHeight: "28.8px"}}>Team Idntica</span></p>
                </div>
                </td>
                </tr>
            </tbody>
        </table>

           </Card>
    </>
  )
}
