import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { Auth } from "aws-amplify";
//import ImageDisplay from '../../components/manageimage/ImageDisplay';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import { Form, Card, Button, Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import Preloader from "../../components/Preloader";
import AddSlider from '../ImageEditor/AddSlider';
import { ProductContext } from '../../ProductProvider';
import PreviewEmail from './PreviewEmail';
//import constIcon from "../../assets/img/const-icon.png";

const config = require('../../config.json');
const fileName='images/email/seasonal';


export default function SeasonalEmail() {
    const { validateLogin, alertClose, alertDetails, setAlertDetails, loggedUser} = useContext(ProductContext);
    const [loaded, setLoaded] = useState(false);
    const [galleryImages, setGalleryImages] = useState('');
    const [collapsed, setCollapsed] = useState(true);
    const [previewCollapsed, setPreviewCollapsed]=useState(true);
    const [imageFile, setImageFile] = useState('');
    const [profileImage, setProfileImage] = useState('vjdepth-logo.png');
    //const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});
    const [newFileName, setNewFileName]=useState('');
    const [emailFileSelection, setEmailFileSelection]=useState('');
    const [emailSubject, setEmailSubject]=useState('');
    const [emailMsgHeader, setEmailMsgHeader]=useState('');


    useEffect(() => {
        alertClose();
        if(loggedUser.isAuthenticated){
            fetchGalleryImages();  
        }else{
            validateLogin();
        }
    }, [loggedUser]);

    const handleDeleteImage = async(event, imageName)=>{
        event.preventDefault();
        setLoaded(false);
      
       // const imageid=imageName.substring(12,imageName.length);
       try{
           if(galleryImages.length<2){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Delete not allowed! Only one image left in Slider"
            }); 
           }else{
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            //console.log("image : ", imageName  );
        await axios.get(`${config.api.invokeURL}/admin/images/remove?imageName=${imageName}`,{
            headers:{
                Authorization: access_token,
                'x-api-key': config.api.key
            }
        }).then((response)=>{
            //console.log(response);
            //setGalleryImages(response.data);
            //setDispMsg("Deleted Successfully");
            setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message:"Deleted Successfully"
            });   
            window.scrollTo(0,0);
            fetchGalleryImages();
        }).catch((error)=>{
           // setDispMsg(error.message);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            window.scrollTo(0,0); 
        })
    }
    }catch(error){
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
        window.scrollTo(0,0); 
    }
    setLoaded(true);
    }

    const toggle = () => {
     //   console.log("clicked");
          setCollapsed(!collapsed);
    }

    const previewToggle = () => {
            setPreviewCollapsed(!previewCollapsed);
    }

    const fetchGalleryImages =async()=>{
        try{
            setLoaded(false);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
          //const fileName='images/gallery/';
         // const bucketName="images.stjosephhospitaldindigul.in";
      //await axios.get(`${config.api.invokeUrl}/images/gallery?fileName=${keyName}&bucketName=${bucketName}`,{
        //const fileName='images/doctor/';
        //const fileName='images/email/seasonal';
        const bucketName="images.idntica.com";
      await axios.get(`${config.api.invokeURL}/admin/images/slider?fileName=${fileName}&bucketName=${bucketName}`,{
            headers: {
              Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
           // console.log("Response : ", response);
            const finalList=[];
            if(response.data.length>0){
              for (const i in response.data){
                const tValue=response.data[i];
                const tPathName=tValue.split("/");
                const tBreakUp=tPathName[3];
                const tempValue={};
                tempValue.fullPathName=response.data[i];
                tempValue.fileName=tBreakUp;

                //const tdoctorName=tBreakUp[1];
                //const tempDName=tdoctorName.split(".");
                //tempValue.doctorName=tempDName[0];
                //console.log("tempvalue : ", tempValue);
               // tempValue.projectSize=tBreakUp[2];
                //tempValue.projectDate=tBreakUp[3];
                
                finalList.push(tempValue);
              }
            }
      
           // console.log(finalList);
            setGalleryImages(finalList);
            //setDispMsg(response.data.length + " - Images are found in your Gallery");
            setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message:response.data.length + " - Images are found in Slider section"
            }); 
            setLoaded(true);
            window.scrollTo(0,0); 
        }).catch((error)=>{
            //console.log(error.message);
            //setDispMsg(error.message);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            window.scrollTo(0,0); 
        })
    }catch(error){
       // setDispMsg(error.message);
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
        window.scrollTo(0,0); 
    }
    setLoaded(true);
    }

/*const addImageOnS3=async()=>{
    try{ 
        setLoaded(false);
      
        const fileName=projectName+"_"+new Date().getTime();
      const newFileName=fileName+".jpeg";
      const bucketName=config.s3.bucketName+keyName;
      //console.log("bucket name : ", bucketName);
      //console.log("file name : ", newFileName);

      var file = imageFile;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
      //console.log("New file : ", newFile);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      //console.log("access token : ", access_token);
      await axios.get(`${config.api.invokeURL}/images/presigned?fileName=${newFileName}&bucketName=${bucketName}`,{
           headers:{
              Authorization: access_token,
              'x-api-key': config.api.key
           }
       }).then(async(response)=>{
           console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
            // console.log("Res : ", res);
             if(res.status===200){
                fetchGalleryImages();
                 setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                 var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
                 toggle();

             }
        }).catch((error)=>{
            //console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            window.scrollTo(0,0);   
         // setDispMsg(error.message);
         // hideLoader();
       })
    }catch(error){
      //console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    setLoaded(true);
    window.scrollTo(0,0);   
    }
    setLoaded(true);

}*/

    const postOnS3 = async(event)=>{
    event.preventDefault();
    try{ 
        if(imageFile===""||newFileName==="")
        {
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Enter all the details before Adding!"
            }); 
            setLoaded(true);
            window.scrollTo(0,0);   

        }else{
        setLoaded(false);
        const fileName=newFileName
        //const fileName=projectName+"_"+new Date().getTime();
     // const newFileName=newDoctorDept+"$"+newDoctorName+".jpeg";
     const tnewFileName=fileName+".jpeg";
     const keyName='images/email/seasonal';

      const bucketName=config.s3.bucketName+keyName;
      //console.log("bucket name : ", bucketName);
      //console.log("file name : ", newFileName);

      var file = imageFile;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], tnewFileName, {type: 'image/jpeg'});
      //console.log("New file : ", newFile);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      //console.log("access token : ", access_token);
      await axios.get(`${config.api.invokeURL}/admin/images/presigned?fileName=${tnewFileName}&bucketName=${bucketName}`,{
           headers:{
              Authorization: access_token,
              'x-api-key': config.api.key
           }
       }).then(async(response)=>{
           //console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
            // console.log("Res : ", res);
             if(res.status===200){
                fetchGalleryImages();
                 setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                 var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
                 toggle();

             }
        }).catch((error)=>{
            //console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            window.scrollTo(0,0);   
         // setDispMsg(error.message);
         // hideLoader();
       })
    }
    }catch(error){
      //console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    setLoaded(true);
    window.scrollTo(0,0);   
    }
    setLoaded(true);

    } 

    const handleImageSelect=(e, item)=>{
        setEmailFileSelection(item);
    }

    const handleEmailPreview=(event)=>{
        event.preventDefault();
        //console.log("Send");
        alertClose();
        previewToggle();
    }

    const handleSendEmail =async(event)=>{
        event.preventDefault();
        try{
            if(emailSubject===""){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Email Subject cannot be Empty"
                }); 
            }else if(emailMsgHeader===""){
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Email Header cannot be Empty"
                }); 

            }else{
            setLoaded(false);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            await axios.get(`${config.api.invokeURL}/admin/email/seasonal-sender?subjectLine=${emailSubject}&headerMsg=${emailMsgHeader}&selectedImage=${emailFileSelection}`,{
                headers: {
                Authorization: access_token,
                'x-api-key': config.api.key
                }}, 
            ).then((response)=>{
            //console.log("Response : ", response);
            // setClientDetails(response.data);
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: "Email Sent Successfully"
                });  
                //console.log(response);
                setLoaded(true);
            }).catch((error)=>{
                //console.log(error);
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + error.message
                }); 
                setLoaded(true);
                //setDispMsg(error.message);
            })
        }
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            window.scrollTo(0,0);   
        
        }

    }

    return (
        <div> <h3>Seasonal Email Images</h3>
        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} fade="true" isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message} </Alert>
        {/* Add Images */}
        <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
        <ModalHeader toggle={toggle}>
            Add images            
        </ModalHeader>
        <ModalBody>
            {/*<AddImge setImageFile={setImageFile} profileImage={profileImage} /> */}
            <AddSlider setNewFileName={setNewFileName} setImageFile={setImageFile} profileImage={profileImage} alertDetails={alertDetails} alertClose={alertClose}/>

        </ModalBody>
        <ModalFooter>
    <Button
    color="primary"
    onClick={(event)=>postOnS3(event)}
    >
    Add Image
    </Button>
    {' '}
    <Button onClick={toggle}>
    Cancel
    </Button>
</ModalFooter>
        </Modal>
        {/* Preview Email */}
        <Modal toggle={previewToggle} isOpen={!previewCollapsed} style={{top:'10%'}}>
            <ModalHeader toggle={previewToggle}>
                Preview Email            
            </ModalHeader>
            <ModalBody>
                <PreviewEmail emailFileSelection={emailFileSelection} alertDetails={alertDetails} alertClose={alertClose} emailSubject={emailSubject}
                setEmailSubject={setEmailSubject} emailMsgHeader={emailMsgHeader} setEmailMsgHeader={setEmailMsgHeader} previewToggle={previewToggle}/>
            </ModalBody>
        <ModalFooter>
        <Button color="primary" onClick={(event)=>handleSendEmail(event)}>
            Send Email
        </Button>
        {' '}
        <Button onClick={previewToggle}>
            Cancel
        </Button>
        </ModalFooter>
        </Modal>
        <Dropdown>
        <Dropdown.Toggle as={Button} onClick={(event)=>{toggle(event)}} variant="secondary" className="text-dark me-2">
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        <span>New</span>
        </Dropdown.Toggle>
        </Dropdown>
        <Card>
            <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center', textAlign:'center'}}>
            {galleryImages.length>0? galleryImages.map((images, index)=>        <Card key={index}>
            <img src={`${config.s3.invokeUrl}${images.fullPathName}`} alt="1" height="200" width="200"/> <br />
            <span><Form.Check inline type="radio" name="test" value={images.fileName} onChange={event=>handleImageSelect(event, images.fullPathName)}/>
            {images.fileName}          </span>
            <FontAwesomeIcon id="TooltipExample" icon={faTrash} className="me-2" onClick={(event)=>handleDeleteImage(event, images.fullPathName)}/>
        </Card>
        ):<div>No Images found!</div>} 
        </div>
        <Button color="primary" onClick={(event)=>{handleEmailPreview(event)}}>Preview Email</Button>
        </Card>
        </div>
    )
}
