import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { Auth } from "aws-amplify";
import {Breadcrumb, BreadcrumbItem  } from "reactstrap";
import { Link} from "react-router-dom";
import {Routes} from '../../routes';
import Preloader from "../../components/Preloader";
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import { Col, Row, Form, Button, ButtonGroup, Table, Dropdown } from '@themesberg/react-bootstrap';
import { faEye, faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductContext } from '../../ProductProvider';
import ViewOrder from './ViewOrder';

const config = require('../../config.json');

export default function OrderDetails() {
  const { validateLogin, alertClose , alertDetails, setAlertDetails, loggedUser} = useContext(ProductContext);
  const [loaded, setLoaded] = useState(true);
  const [orderViewCollapsed, setOrderViewCollapsed] = useState(true);
  const [orderData, setOrderData]= useState([]);
  const [selectedOrder, setSelectedOrder] = useState();
  const [batchYear, setBatchYear] = useState([]);
  const [selectedYear, setSelectedYear]=useState('');


  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      processBatchYear();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }
   // console.log(batchYear);
  },[loggedUser])

  const processBatchYear=()=>{
    const current_datetime = new Date();
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    //const lastTenYear=date_year-10;
    var tbatchYear=[];
    for(var i=0; i<10;i++){
      tbatchYear.push(date_year-i);
    }
  //  console.log("batch year : ", tbatchYear);
    setBatchYear(tbatchYear);
    setSelectedYear(date_year);
    //fetchCurrentYearDetails(date_year);
  }

    const fetchOrderDetails=async()=>{
       // console.log("fetching clients list");
        try{
          setLoaded(false);
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          //const clientID="VJDEPTH";
          //const status="Active";
          //const selectedBatch="All";
          await axios.get(`${config.api.invokeURL}/admin/clients/order?transactionYear=${selectedYear}`,{
              headers: {
                Authorization: access_token,
                'x-api-key': config.api.key
              }}, 
          ).then((response)=>{
           // console.log("Response : ", response);
              setOrderData(response.data);
              setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: response.data.length + " - Orders Found"
              });  
              //console.log(response);
              setLoaded(true);
          }).catch((error)=>{
              //console.log(error);
              setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message:"Error Occured - " + error.message
              }); 
              setLoaded(true);
              //setDispMsg(error.message);
          })
        }catch(error){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
        }
    }

    const orderViewToggle=()=>{
      setOrderViewCollapsed(!orderViewCollapsed);
    }

    const handleViewOrders=async(event, item)=>{
      event.preventDefault();
      setSelectedOrder(item);
      orderViewToggle();
  }
    
  return (
    <> 
    <Preloader show={loaded ? false : true} />
    <Breadcrumb>
    <BreadcrumbItem>
      <Link to={Routes.LandingPage.path}>
        Home
      </Link>
    </BreadcrumbItem>
    <BreadcrumbItem >
      Expiring Accounts
    </BreadcrumbItem>
  </Breadcrumb>

        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
         {/*View Orders*/}
        <Modal toggle={orderViewToggle} isOpen={!orderViewCollapsed} style={{top:'10%', maxWidth:'800px'}}>
          <ModalHeader toggle={orderViewToggle}>
            View Order
          </ModalHeader>
          <ModalBody>    
          <div>
                  <ViewOrder selectedOrder={selectedOrder}/>                        
            </div>
          </ModalBody>
          <ModalFooter>
            {' '}
            <Button onClick={orderViewToggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>

        <div className="table-settings">
       
       <Row className="justify-content-between align-items-center">
       <Col md={6} className="mb-3">
               
      <Form.Group id="searchstud">
      <Form.Label>Select the Year</Form.Label>
          <Form.Select value={selectedYear} 
             onChange={(event)=>{setSelectedYear(event.target.value)}}>
            {batchYear.map(item => {
               return (
               <option key={item} value={item}>{item}</option>
               );
             })}
          </Form.Select>
            </Form.Group>
     </Col>
      <Col md={4}>
      <Button variant="primary" type="submit" onClick={(event)=>{fetchOrderDetails(event, selectedYear)}}>Search</Button>
                
    </Col>
        {/*} <Col className="mb-3" xs={8} md={6} lg={3} xl={4}>
           <InputGroup>
             <InputGroup.Text>
               <FontAwesomeIcon icon={faSearch} />
             </InputGroup.Text>
             <Form.Control type="text" placeholder="Search" value={q} onChange={(e) => setQ(e.target.value)}/>
             <Button variant="primary" type="submit" >Submit</Button>
           </InputGroup>
                           </Col>*/}
       </Row>
       </div>

        <Table responsive hover className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Client ID</th>
                <th>ORG Name</th>
                <th>Order Date</th>
                <th>Order Status</th>
                <th>Discount</th>
                <th>GST</th>                
                <th>Total</th>

                </tr>
            </thead>
            <tbody>
            {orderData.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.clientId}</td>
            <td>{item.orgName}</td>
            <td>{item.transactionDate}</td>
            <td>{item.txStatus}</td>
            <td>{item.discountAmount}</td>
            <td>{item.gstCharges}</td>
            <td>{item.subTotalwithGST}</td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleViewOrders(event, item)}} >
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Orders
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
        </Table>
    </>
    )
}
