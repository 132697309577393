import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { Routes } from "../routes";
import { Auth } from "aws-amplify";

// pages
//import Presentation from "./Presentation";
//import DashboardOverview from "./dashboard/DashboardOverview";
import Signin from "./auth/Signin";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
//import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import SlideViewer from './ImageEditor/SlideViewer';

import MyProfile from './profile/MyProfile';

import ClientsView from './clients/ClientsView';
import ExpiringAccounts from './clients/AccountsExpiring';
import SeasonalEmail from './email/SeasonalEmail';

import { ProductContext } from '../ProductProvider';
import OrderDetails from './clients/OrderDetails';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  /*const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }*/

  //const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  /*const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }*/

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
         {/*} <Footer toggleSettings={toggleSettings} showSettings={showSettings} />*/}
        </main>
      </>
    )}
    />
  );
};

export default function HomePage() {
  const history= useHistory();
  const { loggedUser, setLoggedUser, validateLogin } = useContext(ProductContext);


  useEffect(() => {
   validateLogin();   
  },[]);

/*  const validateLogin = async() =>{
    try{
       await Auth.currentAuthenticatedUser()
      .then(async(user)=>{
        console.log("user : ", user);
        await setLoggedUser({...loggedUser,
          name:user.attributes['name'],
          email:user.attributes['email'],
          //currentStatus:user.attributes['locale'],
          DOB:user.attributes['birthdate'],
          profile:user.attributes['profile'],
          mobile:user.attributes['phone_number'],
          website:user.attributes['website'],
          isAuthenticated:true
        });
       // navigate("/");   
       history.push(Routes.LandingPage.path);
        
      })
      .catch(() =>{
        //console.log("error:", error);
        setLoggedUser({...loggedUser,
          name:'',
          email:'',
         // currentStatus:'',
          DOB:'',
          profile:'',
          mobile:'',
          website:'',
          isAuthenticated:false
        }); 
        history.push(Routes.Signin.path);

      })     
    }catch(error){
     // console.log(error);
    }
  }*/

  return (

  <Switch>
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    {/*<RouteWithLoader exact path={Routes.Signup.path} component={Signup} />*/}
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    {/*<RouteWithLoader exact path={Routes.Lock.path} component={Lock} />*/}
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.LandingPage.path} component={MyProfile} />
   {/*} <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} />*/}
    <RouteWithSidebar exact path={Routes.MyProfile.path} component={MyProfile} />
    <RouteWithSidebar exact path={Routes.ResetPassword.path} component={ResetPassword} />

    {/*Images*/}
    <RouteWithSidebar exact path={Routes.SlideViewer.path} component={SlideViewer} />

    {/*Clients*/}
    <RouteWithSidebar exact path={Routes.Clients.path} component={ClientsView} />
    <RouteWithSidebar exact path={Routes.ExpiringAccounts.path} component={ExpiringAccounts} />
    <RouteWithSidebar exact path={Routes.OrderDetails.path} component={OrderDetails} />

    {/*Email*/}
    <RouteWithSidebar exact path={Routes.SeasonalEmail.path} component={SeasonalEmail} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
)
}

