
import React, { useState, useContext } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faSignOutAlt, faTimes, faInbox, faMailBulk, faReceipt } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

import { Routes } from "../routes";
//import ThemesbergLogo from "../assets/img/themesberg.svg";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
//import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import { ProductContext } from "../ProductProvider";

export default (props = {}) => {
  const location = useLocation();
  const history=useHistory();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const { loggedUser, setLoggedUser } = useContext(ProductContext);

  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  const handleSignOut=async()=>{
    //console.log("testing");
    try{
      await Auth.signOut()
      .then((res)=>{
        setLoggedUser({...loggedUser,
          name:'',
          email:'',
          DOB:'',
          profile:'',
          mobile:'',
          website:'',
          isAuthenticated:false,
          isAdmin:false
        }); 
        history.push(Routes.Signin.path);

      }).catch((error)=>{
        console.log("Error ", error.message);
      })
    }catch(error){
      console.log("Error ", error.message);
    }
  }

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey} style={{backgroundColor:'#262B40'}}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center" style={{backgroundColor:'#262B40'}}>
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.LandingPage.path}>
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
               {/*} <div className="user-avatar lg-avatar me-4">
                  <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
  </div>*/}
                <div className="d-block">
                  <h6>Hi, {loggedUser.name}</h6>
                  <Button as={Link} variant="secondary" size="xs" to={Routes.Signin.path} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem title="Admin Idntica" link={Routes.LandingPage.path} image={ReactHero} />

              {/*<NavItem title="Overview" link={Routes.DashboardOverview.path} icon={faChartPie} />*/}
              <NavItem title="Slider" link={Routes.SlideViewer.path} badgeText="Pro" icon={faInbox} />
              <CollapsableNavItem eventKey="tables/" title="Clients" icon={faMailBulk}>
                <NavItem title="View Clients" link={Routes.Clients.path} icon={faChartPie} />
                <NavItem title="Expiring Accounts" link={Routes.ExpiringAccounts.path} icon={faChartPie} />
              </CollapsableNavItem>

              <CollapsableNavItem eventKey="tables/" title="Reports" icon={faReceipt}>
                <NavItem title="Expiring Accounts" link={Routes.ExpiringAccounts.path} icon={faChartPie} />
                <NavItem title="View Orders" link={Routes.OrderDetails.path} icon={faChartPie} />
              </CollapsableNavItem>

              <CollapsableNavItem eventKey="tables/" title="Email" icon={faMailBulk}>
                <NavItem title="Seasonal Email" link={Routes.SeasonalEmail.path} />
              </CollapsableNavItem>

             {/*} <NavItem external title="Plugins" link="https://demo.themesberg.com/volt-pro-react/#/plugins/datatable" target="_blank" badgeText="Pro" icon={faChartPie} />*/}

              <Dropdown.Divider className="my-3 border-indigo" />
              <NavItem title="Change Password" link={Routes.ResetPassword.path} icon={faChartPie} />
             {/*} <NavItem icon={faSignOutAlt} title="Logout" link="#" onClick={handleSignOut} />

              <NavItem external title="Themesberg" link="https://themesberg.com" target="_blank" image={ThemesbergLogo} />*/}
              <Button as={Link} to="#" variant="secondary" className="upgrade-to-pro"><FontAwesomeIcon icon={faSignOutAlt} className="me-1" />Log Out</Button>
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
