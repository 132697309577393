import React, {useState} from 'react';
import { Auth } from 'aws-amplify';
import { Routes } from './routes';
import {useHistory} from 'react-router-dom';

//import axios from "axios";
//import { withRouter, useHistory} from "react-router-dom";

const ProductContext = React.createContext();;
const config = require('./config.json');

const ProductProvider = (props) => {
let history=useHistory();
const [loggedUser, setLoggedUser] = useState({
    name: 'Guest', email: '', website:'', profile:'Guest', DOB:'', isAuthenticated:false, mobile:''});
//const [dispMsg, setDispMsg]=useState('');
//const [searchResults, setSearchResults] = useState([]);
const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});
const [myProfileImage, setMyProfileImage] = useState('defaultAvatar.jpg');

const alertClose=()=>{
    setAlertDetails({
        ...alertDetails,
        style:"",
        open:false,
        message:""
    });
}

const validateLogin = async() =>{
    try{
       await Auth.currentAuthenticatedUser()
      .then(async(user)=>{
        //console.log("user : ", user);
        //var tadmin=false;
        if(user.attributes['given_name']==="AdminIdntica" || user.attributes['given_name']==="Idntica"){
        await setLoggedUser({...loggedUser,
          name:user.attributes['name'],
          email:user.attributes['email'],
          profile:user.attributes['profile'],
          DOB:user.attributes['birthdate'],
          website:user.attributes['website'],
          nickname:user.attributes['nickname'],
          gender:user.attributes['gender'],
          family_name:user.attributes['family_name'],
          phone_number:user.attributes['phone_number'],
          //website:user.attributes['website'],
          isAuthenticated:true,
          isAdmin:true
        });
        const tnickname=user.attributes['nickname'];
        const tImageName=user.attributes['nickname']+".jpeg";
        setMyProfileImage(`${config.s3.profileUrl}${tnickname}${"/"}${tImageName}`);
      }else{
        setLoggedUser({...loggedUser,
          name:'',
          email:'',
         // currentStatus:'',
          DOB:'',
          profile:'',
          //mobile:'',
          website:'',
          nickname:'',
          gender:'',
          family_name:'',
          phone_number:'',
          isAuthenticated:false,
          isAdmin:false
        }); 
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"You are not authorised to access this portal!"
      }); 
      //history.push(Routes.Signin.path);
      }
       // navigate("/");   
   //    history.push(Routes.DashboardOverview.path);
        
      })
      .catch((error) =>{
      //  console.log("error:", error);
        setLoggedUser({...loggedUser,
          name:'',
          email:'',
         // currentStatus:'',
          DOB:'',
          profile:'',
          //mobile:'',
          website:'',
          nickname:'',
          gender:'',
          family_name:'',
          phone_number:'',
          isAuthenticated:false,
          isAdmin:false
        }); 
        history.push(Routes.Signin.path);

      })     
    }catch(error){
     // console.log(error);
    }
  }

  const generateDate=()=>{
    const current_datetime = new Date();
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()).toString();
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    const accountNo = tempUserId.concat(date_year,"-",month_value,"-", date_value);
    //setUserId(accountNo);
    //setMemberInfo({...memberInfo, userId:accountNo});
    return accountNo;
    //console.log("acc : ", accountNo);
  
}

    return (
        <ProductContext.Provider value={{
            loggedUser,
            setLoggedUser,
            alertClose,
            alertDetails,
            setAlertDetails,
            validateLogin,
            generateDate
        }}>
        {props.children}
        </ProductContext.Provider>
    )
}

export {ProductProvider, ProductContext};
