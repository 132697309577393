import React, { useEffect,  useState, useContext } from 'react';
import axios from 'axios';
import { Auth } from "aws-amplify";
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import {Input, Alert} from 'reactstrap';
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import { allPackages } from '../../AppData';

const config = require('../../config.json');

export default function AccountsAddForm(props) {
const {selectedClient} = props;
const { alertClose , alertDetails, setAlertDetails, loggedUser, validateLogin, generateDate} = useContext(ProductContext);
const [loaded, setLoaded]= useState(true);
//const [selectedAccounts, setSelectedAccounts] = useState();
const [accountDetails, setAccountDetails] = useState({packageId:'IDN-WH-01', packageName:'Bronze Package - Web Hosting', packagePrice:0, packageDetails:[]});

useEffect(()=>{
   // console.log("Selected Client : ", selectedClient);
    if(loggedUser.isAuthenticated){
      alertClose();
    //fetchclientAccounts();
    processAccSelection("IDN-WH-01");
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }
   // console.log(batchYear);
  },[loggedUser])

const generateOrderID = async () => {
   // const user = selectedAccount.clientID;
    const current_datetime = new Date();
    //const current_local_datetime = new Date().toLocaleString('en-US', {timeZone: 'Asia/Kolkata'});
    const orderNoTemp = "";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    const order_no = orderNoTemp.concat(date_year,month_value, date_value, hours_value, minutes_value);
    //const orderRef_id = orderNoTemp.concat(user, date_year,month_value, date_value, hours_value, minutes_value);
    return order_no;
  //const tempTransactionDate="";
    //const orderDate = tempTransactionDate.concat(current_datetime.getFullYear(),"-",month_value,"-",date_value);
    //setOrderId(order_no);
    //setOrderRefId(orderRef_id);

   // console.log("Order No : ", order_no);
   // console.log("Ref ID : ", orderRef_id);
    //setTransactionDate(current_local_datetime);
    //await Cookies.set('orderid', order_no, { expires: 7 });

}

const generateOrderRefID = async () => {
    const user = accountDetails.packageId;
    const current_datetime = new Date();
    //const current_local_datetime = new Date().toLocaleString('en-US', {timeZone: 'Asia/Kolkata'});
    const orderNoTemp = "";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    //const order_no = orderNoTemp.concat(date_year,month_value, date_value, hours_value, minutes_value);
    const orderRef_id = orderNoTemp.concat(user, date_year,month_value, date_value, hours_value, minutes_value);
    return orderRef_id;
  //const tempTransactionDate="";
    //const orderDate = tempTransactionDate.concat(current_datetime.getFullYear(),"-",month_value,"-",date_value);
    //setOrderId(order_no);
    //setOrderRefId(orderRef_id);

   // console.log("Order No : ", order_no);
   // console.log("Ref ID : ", orderRef_id);
    //setTransactionDate(current_local_datetime);
    //await Cookies.set('orderid', order_no, { expires: 7 });

}


const validateFields=()=>{
    var returnValue="Failed";
    if(accountDetails.totalAmount<1){
        returnValue="Failed";
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Order Amount is very less to be processed"
        }); 
    }else {
        returnValue="Passed";
    }
    return returnValue;
}

const handleAddNewAccount = async (event) => {
    event.preventDefault();
    try {
        const validationResult=validateFields();
        if(validationResult==="Passed"){
        setLoaded(false);
        const orderid= await generateOrderID();
        const orderRefId=await generateOrderRefID();
        const tyears=parseInt(accountDetails.years);
        const trenew=await generateRenewYear(accountDetails.packageStartDate, tyears);
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        //const orderid=orderId;
        const params = {
            "packageId": accountDetails.packageId,
            "packageName": accountDetails.packageName,
            "packagePrice": accountDetails.packagePrice,
            "packageDetails": accountDetails.packageDetails,    
            "orderId": orderid,
            "accId": orderRefId,
            "accDetails":"NA",
            "accStatus":"Active",
            "clientId":selectedClient.clientId,
            "packageStartDate":accountDetails.packageStartDate,
            "validFrom":accountDetails.packageStartDate,
            "validTo":trenew,
            "years":tyears
        };
        //console.log("DB Values : ", params);
        await axios.post(`${config.api.invokeURL}/admin/clients/accounts`, params,{
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }})
        .then(()=>{
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message:"Order processed Successfully"
        }); 
        setLoaded(true);
        })
        .catch((error)=>{
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            })
    }
    setLoaded(true);
    }catch (err) {
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + err.message
        }); 
        setLoaded(true);
    }
}

const processAccSelection=async(tpackageid)=>{
    //console.log("event : ", event);
    const selectedAcc=allPackages.find(acc=>(acc.packageId===tpackageid));
    //setSelectedAccounts(selectedAcc);
    //const gst=parseInt(selectedAcc.packagePrice)*16/100;
    //const subTotal=parseInt(selectedAcc.packagePrice)+gst;
    //const totalAmount=subTotal-0;
    //console.log("Acc : ", selectedAcc);
    const orderDate=await generateDate();
    setAccountDetails({...accountDetails,
    packageId:selectedAcc.packageId,
    packageName:selectedAcc.packageName,
    packagePrice:selectedAcc.packagePrice,
    packageDetails:selectedAcc.packageDetails,
    packageStartDate:orderDate,
    //gst:gst,
    years:1,
    //subTotal:subTotal,
    //totalAmount:subTotal,
    //discount:0
    });
}

const generateRenewYear = async(tYear, ext)=>{
    const tempDate= new Date(tYear);
    var tempYear="";
    tempYear = (tempDate.getFullYear()+ext).toString();
    const tempMonth = (tempDate.getMonth()+1).toString();
    const tempDate1= tempDate.getDate().toString();
    var tempRenew = "";
    const renewDate = tempRenew.concat(tempYear,"-",tempMonth,"-",tempDate1);
    return renewDate;
}

/*const processDiscountCalc=(event)=>{
    const discount=event.target.value;
    const totalAmount=orderDetails.subTotal-discount;
    setOrderDetails({...orderDetails,
    discount:discount,
    totalAmount:totalAmount});
}

const processYearsCalc=(event)=>{
    const years=parseInt(event.target.value);
    //console.log("price : ", selectedAccounts);
    const subTotal=parseFloat(years*parseFloat(selectedAccounts.packagePrice)).toFixed(2);
    //console.log("Total Amount : ", totalAmount);
    const gst=((subTotal*16)/100).toFixed(2);
    const totalAmount=subTotal-orderDetails.discount;
    //console.log("GST : ", gst);
    setOrderDetails({...orderDetails,
        years:years,
        totalAmount:totalAmount,
        gst:gst,
        subTotal:subTotal

    });
}*/


  return (
  <>
      <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

    <div>Process Order</div>
    <h4>Client Details</h4>

    <Card border="light" className=" bg-white shadow-sm mb-4">
       <Card.Body>     
         <Form>
         <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="packageName">
                <Form.Label>Client ORG Name</Form.Label>
                <Input type="text" value={selectedClient.clientOrg} disabled
               />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="packagePrice">
                <Form.Label>Client POC Name</Form.Label>
                <Input type="text" value={selectedClient.clientPOC} disabled
               />
              </Form.Group>
            </Col>
    </Row>
    <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="packageName">
                <Form.Label>Email</Form.Label>
                <Input type="text" value={selectedClient.clientEmail} disabled
               />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="packagePrice">
                <Form.Label>Phone</Form.Label>
                <Input type="text" value={selectedClient.clientPhone} disabled
               />
              </Form.Group>
            </Col>
    </Row>
</Form>
</Card.Body>
</Card>
<h4>Package Details</h4>
    <Card border="light" className=" bg-white shadow-sm mb-4">
       <Card.Body>     
         <Form>
         <Row>
         <Form.Group id="otp">
            <Form.Label>Select the Account</Form.Label>
            <Input type="select" name="accId"
                value={accountDetails.packageId} 
                onChange={(event)=>{processAccSelection(event.target.value)}}
                onSelect={(event)=>{processAccSelection(event.target.value)}}
            >
                {allPackages.map(item => {
            return (<option key={item.packageId} value={item.packageId}>{item.packageName}</option>);
            })}
            </Input>
        </Form.Group>
         </Row>
         <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="packageName">
                <Form.Label>Package Name</Form.Label>
                <Input type="text" value={accountDetails.packageName} disabled
                onChange={(event)=>{setAccountDetails({...accountDetails, packageName:event.target.value})}}
               />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="packagePrice">
                <Form.Label>Package Price</Form.Label>
                <Input type="text" value={accountDetails.packagePrice} disabled
                onChange={(event)=>{setAccountDetails({...accountDetails, packagePrice:event.target.value})}}
               />
              </Form.Group>
            </Col>
    </Row>
    <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="packageName">
                <Form.Label>Package Start Date</Form.Label>
                <Input type="date" value={accountDetails.packageStartDate} 
                onChange={(event)=>{setAccountDetails({...accountDetails, packageStartDate:event.target.value})}}
               />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="packagePrice">
                <Form.Label>Quantity / Years</Form.Label>
                <Input type="number" value={accountDetails.years} 
                onChange={(event)=>{setAccountDetails({...accountDetails, years:event.target.value})}}
               />
              </Form.Group>
            </Col>
    </Row>
    <Row>
    <Form.Label>Package Details</Form.Label>
    {accountDetails.packageDetails.length>0 && (accountDetails.packageDetails).map((plan, index)=>(<ul key={index}><li key={plan}>{plan}</li> </ul>))} 
    </Row>
    </Form>
    </Card.Body>
    </Card>

    <Button onClick={(event)=>{handleAddNewAccount(event)}}>Add</Button>

    </>
  )
}
