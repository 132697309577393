import React, { useEffect, useContext, useState } from 'react'
import axios from 'axios';
import { Auth } from "aws-amplify";
import {Alert  } from "reactstrap";
import { Button, Dropdown, Table, ButtonGroup } from '@themesberg/react-bootstrap';
import { faEye, faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';

const config = require('../../config.json');

export default function ClientAccountsView(props) {
    const {selectedAccount} = props;
    const { alertClose , alertDetails, setAlertDetails, loggedUser, validateLogin} = useContext(ProductContext);
    const [loaded, setLoaded]= useState(true);
    const [clientAccounts, setClientAccounts]=useState([]);

    useEffect(()=>{
      if(loggedUser.isAuthenticated){
        alertClose();
        fetchclientAccounts();
      }else{
      validateLogin();
      alertClose();
      }
      return()=>{
        alertClose();
      }
     // console.log(batchYear);
    },[loggedUser])

    const fetchclientAccounts=async()=>{
        try{
            setLoaded(false);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            const clientid = selectedAccount.clientId;
            await axios.get(`${config.api.invokeURL}/client/accounts/${clientid}`,{
                headers: {
                  Authorization: access_token,
                  'x-api-key': config.api.key
                }}, 
            ).then((response)=>{
                setClientAccounts(response.data);
                setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: response.data.length + " - Records Found"
                });  
                setLoaded(true);
            }).catch((error)=>{
                //console.log(error);
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + error.message
                }); 
                setLoaded(true);
                //setDispMsg(error.message);
            })
          }catch(error){
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          }); 
          setLoaded(true);
          }
          setLoaded(true);
    }

  return (
    <> 
    <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
          {/*Edit*/}
        <Table responsive hover className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Account ID</th>
                <th>Package Name</th>
                <th>Start Date</th>
                <th>Valid Till</th>
                </tr>
            </thead>
            <tbody>
            {clientAccounts.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.accId}</td>
            <td>{item.packageName}</td>
            <td>{item.packageStartDate}</td>
            <td>{item.validTo}</td>
            </tr>)}
            </tbody>
        </Table>
    </>  )
}
