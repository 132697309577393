import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {Tooltip } from "reactstrap";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
//import { Card } from 'react-bootstrap';
import { Card } from '@themesberg/react-bootstrap';

const config = require('../../config.json');

export default function ImageDisplay(props) {
    const {imageSrc, handleDeleteImage}=props;
   // const [view, setView]=useState(false);

   /* const toggle=()=>{
        setView(!view);
    }*/
    return (
        <Card>
                        <img src={`${config.s3.invokeUrl}${imageSrc}`} alt="1" height="200" width="200"/> <br />
                       {/*} <span><Form.Check inline type="radio" name="test" value={doctorName} onClick={setEmailFileSelection(imageSrc)}/>
                        {doctorName}          </span>*/}

                        <FontAwesomeIcon id="TooltipExample" icon={faTrash} className="me-2" onClick={(event)=>handleDeleteImage(event, imageSrc)}/>
                        {/*<Tooltip
                            flip
                            target="TooltipExample"
                            toggle={toggle}
                        >
                            Hello world!
    </Tooltip>*/}
                        {/*<button onClick={(event)=>props.handleDeleteImage(event, props.imageSrc)}>Remove</button>*/}

        </Card>
    )
}
